import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player"
import { useQueryParam, StringParam } from "use-query-params"

import Layout from "../components/layout"
import SEO from "../components/seo"
import t from "../locale"

const Page = ({ pageContext }) => {
  const [videoToPopup, setVideoToPopup] = useState(null)
  const {
    image,
    flexibleContent,
    mainTitle,
    prev,
    next,
    postLanguage,
  } = pageContext

  const lang = postLanguage.slug
  const [pageParam] = useQueryParam("page", StringParam)

  const handleVideoClick = item => {
    setVideoToPopup(item)
  }

  const handlePopupClick = () => {
    setVideoToPopup(null)
  }

  useEffect(() => {
    ;[...document.getElementsByClassName("menu-link")].forEach(item => {
      item.classList.add("projects-menu")
    })
    document
      .getElementsByClassName("header__logo-container")[0]
      .classList.add("projects-menu")
  }, [])

  return (
    <Layout
      lang={pageContext.postLanguage.slug}
      postTranslations={pageContext.postTranslations}
      postLanguage={pageContext.postLanguage.name}
    >
      <SEO
        title={
          pageContext.data.seo.title
            ? pageContext.data.seo.title
            : pageContext.data.title
        }
        description={
          pageContext.data.seo.metaDesc && pageContext.data.seo.metaDesc
        }
        image={
          pageContext.data.seo.opengraphImage &&
          pageContext.data.seo.opengraphImage.localFile.publicURL
        }
      />

      <div
        className="single-project-header"
        style={{ backgroundImage: `url(${image})` }}
      >
        {pageParam === "presentation" && (
          <div className="single-project-header__back">
            <div className="container">
              <Link to="/presentation/">Aftur til framløgu</Link>
            </div>
          </div>
        )}
        <h1
          className="single-project-header__title"
          dangerouslySetInnerHTML={{ __html: mainTitle }}
        />
        <a href="#start" className="single-project-header__arrows">
          <img
            src={require("../assets/images/ico-arrow-down.png")}
            alt="See more"
          />
        </a>
      </div>
      <span className="anchor" id="start"></span>
      <div className="main-section">
        <div className="container">
          {flexibleContent.map((item, index) => (
            <div key={index}>
              {item.fieldGroupName ===
              "project_Project_FlexibleContent_MainInformations" ? (
                <section className="main-info">
                  <div className="row">
                    <div className="col-md-6">
                      <h2
                        className="main-info__title"
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                    </div>
                    <div className="col-md-6">
                      <p
                        className="main-info__desc"
                        dangerouslySetInnerHTML={{ __html: item.desc }}
                      />
                    </div>
                  </div>
                </section>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_ImageWithBottomText" ? (
                <section className="image-with-text">
                  <img
                    src={item.image.sourceUrl}
                    alt={item.header}
                    className="img-fluid image-with-text__img"
                  />
                  <h2 className="image-with-text__title">{item.header}</h2>
                  <div
                    className="image-with-text__desc"
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </section>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_SingleCenterHeader" ? (
                <section className="single-header">
                  <h2 className="single-header__title">{item.header}</h2>
                </section>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_TitleLeftImageRight" ? (
                <section className="title-left-image-right">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="text-container">
                        {item.leftTitle !== null ? (
                          <h2
                            className="text-container__title"
                            dangerouslySetInnerHTML={{ __html: item.leftTitle }}
                          />
                        ) : (
                          ""
                        )}
                        <p
                          className="text-container__desc"
                          dangerouslySetInnerHTML={{ __html: item.leftDesc }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img
                        src={item.rightImg.sourceUrl}
                        alt={item.leftTitle}
                        className="img-fluid title-left-image-right__image"
                      />
                    </div>
                  </div>
                </section>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_TitleLeftImageRightSmall" ? (
                <section className="title-left-image-right-small">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="text-container">
                        {item.leftTitle !== null ? (
                          <h2
                            className={`text-container__title ${
                              item.centerText === true ? "centered" : ""
                            }`}
                            dangerouslySetInnerHTML={{ __html: item.leftTitle }}
                          />
                        ) : (
                          ""
                        )}
                        <p
                          className="text-container__desc"
                          dangerouslySetInnerHTML={{ __html: item.leftDesc }}
                        />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <img
                        src={item.rightImg.sourceUrl}
                        alt={item.leftTitle}
                        className={`img-fluid title-left-image-right__image ${
                          item.centerImage === true ? "centered" : ""
                        }`}
                      />
                    </div>
                  </div>
                </section>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_TitleRightImageLeft" ? (
                <section className="title-right-image-left">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <img
                        src={item.leftImg.sourceUrl}
                        alt={item.rightTitle}
                        className="img-fluid title-left-image-right__image"
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="text-container">
                        {item.rightTitle !== null ? (
                          <h2
                            className="text-container__title"
                            dangerouslySetInnerHTML={{
                              __html: item.rightTitle,
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <p
                          className="text-container__desc"
                          dangerouslySetInnerHTML={{ __html: item.rightDesc }}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_TitleRightImageLeftSmall" ? (
                <section className="title-right-image-left-small">
                  <div className="row flex-row-reverse align-items-center">
                    <div className="col-md-3">
                      <div
                        className={`text-container ${
                          item.centerText === true ? "centered" : ""
                        }`}
                      >
                        {item.rightTitle !== null ? (
                          <h2
                            className="text-container__title"
                            dangerouslySetInnerHTML={{
                              __html: item.rightTitle,
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <p
                          className="text-container__desc"
                          dangerouslySetInnerHTML={{ __html: item.rightDesc }}
                        />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <img
                        src={item.leftImg.sourceUrl}
                        alt={item.rightTitle}
                        className={`img-fluid title-left-image-right__image ${
                          item.centerImage === true ? "centered" : ""
                        }`}
                      />
                    </div>
                  </div>
                </section>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_TitleBottomImageTop" ? (
                <section className="title-bottom-image-top">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <img
                        src={item.topImg.sourceUrl}
                        alt={item.bottomTitle}
                        className="img-fluid title-bottom-image-top__image"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="text-container">
                        {item.bottomTitle !== null ? (
                          <h2
                            className="text-container__title"
                            dangerouslySetInnerHTML={{
                              __html: item.bottomTitle,
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <p
                          className="text-container__desc"
                          dangerouslySetInnerHTML={{ __html: item.rightDesc }}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_TitleTopImageBottom" ? (
                <section className="title-top-image-bottom">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="text-container">
                        {item.topTitle !== null ? (
                          <h2
                            className="text-container__title"
                            dangerouslySetInnerHTML={{
                              __html: item.topTitle,
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <p
                          className="text-container__desc"
                          dangerouslySetInnerHTML={{ __html: item.rightDesc }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <img
                        src={item.bottomImg.sourceUrl}
                        alt={item.topTitle}
                        className="img-fluid title-top-image-bottom__image"
                      />
                    </div>
                  </div>
                </section>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_SingleHeaderWithText" ? (
                <section className="single-header-with-text">
                  <h2 className="single-header-with-text__title">
                    {item.singleHeader}
                  </h2>
                  <p
                    className="single-header-with-text__desc"
                    dangerouslySetInnerHTML={{ __html: item.singleText }}
                  />
                </section>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_SingleVideo" ? (
                <section className="single-video">
                  <h2
                    className={`single-video__title ${
                      item.showVideoTitle !== true ? "hidden" : ""
                    }`}
                  >
                    {item.videoTitle}
                  </h2>
                  <div
                    className="video-container"
                    onClick={() => handleVideoClick(item.video)}
                    role="presentation"
                  >
                    <img
                      src={item?.thumbnail?.sourceUrl}
                      alt="video thumbnail"
                      className="img-fluid video-container__thumbnail"
                    />
                    <img
                      src={require("../assets/images/ico-play-button.png")}
                      alt=""
                      className="video-container__play-button"
                    />
                  </div>
                </section>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_SingleBigImage" ? (
                <section className="single-big-img">
                  <img
                    src={item.image.sourceUrl}
                    alt=""
                    className="img-fluid single-big-img__image"
                  />
                </section>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_GoldDivider" ? (
                <span className="divider"></span>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_GalleryRow" ? (
                <section className="single-project-gallery">
                  <div className="row">
                    {item.gallery.map((img, index) => {
                      return (
                        <div className="col-sm-4" key={index}>
                          <div
                            className="single-project-gallery__single-img"
                            style={{
                              backgroundImage: `url(${img.img.sourceUrl})`,
                            }}
                          />
                        </div>
                      )
                    })}
                  </div>
                </section>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_SingleButton" ? (
                <section className="single-project-button">
                  <Link className="btn" to={item.link}>
                    {item.label}
                  </Link>
                </section>
              ) : item.fieldGroupName ===
                "project_Project_FlexibleContent_TextFieldBig" ? (
                <section className="single-project-text-big">
                  <div
                    className="single-project-text-big__content"
                    dangerouslySetInnerHTML={{ __html: item.textField }}
                  />
                </section>
              ) : (
                ""
              )}
            </div>
          ))}
          <div className="contact-info">
            <p className="contact-info__text">
              {t(
                "If you have any questions, don't hestitate to",
                pageContext.postLanguage.slug
              )}{" "}
              <Link to={t("/contact/", pageContext.postLanguage.slug)}>
                {t("contact us", pageContext.postLanguage.slug)}
              </Link>
              !
            </p>
          </div>
          <div className="projects-navigation">
            <div className="row align-items-center">
              <div className="col-md-5 col-6">
                {prev && prev.language.slug === lang ? (
                  <div className="inner">
                    <Link
                      to={
                        pageContext.postLanguage.slug !== "fo"
                          ? "/" +
                            pageContext.postLanguage.slug +
                            "/projects/" +
                            prev.slug
                          : "/projects/" + prev.slug
                      }
                      className="btn btn--navigation"
                    >
                      {t("Prev project", pageContext.postLanguage.slug)}
                    </Link>
                    <p className="inner__title">{prev.title}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-5 col-6 offset-md-2">
                {next && next.language.slug === lang ? (
                  <div className="inner--right">
                    <p className="inner__title title title--right">
                      {next.title}
                    </p>
                    <Link
                      to={
                        pageContext.postLanguage.slug !== "fo"
                          ? "/" +
                            pageContext.postLanguage.slug +
                            "/projects/" +
                            next.slug
                          : "/projects/" + next.slug
                      }
                      className="btn btn--navigation"
                    >
                      {t("Next project", pageContext.postLanguage.slug)}
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {videoToPopup != null && (
        <>
          <div className="video-modal">
            <div className="video-container">
              <div className="video-container__video">
                <ReactPlayer
                  url={videoToPopup}
                  width="100%"
                  height="100%"
                  playing={videoToPopup != null ? true : false}
                  controls={true}
                  muted={false}
                />
              </div>
            </div>
          </div>
          <div
            className="video-modal__overlay"
            onClick={handlePopupClick}
            role="presentation"
          ></div>
        </>
      )}
    </Layout>
  )
}

export default Page
